
import React, {useEffect, useState}  from 'react'
import dynamic from 'next/dynamic';
import Link from "next/link";
import Image from 'next/image';

import { getMenuByLocation, MenuData, getAllMenus, getAllServiceMenu  } from '../../lib/menus';
import { getHeaderData } from '../../lib/header';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));
const SocialMedia = dynamic(() => import('../Common/SocialMedia'));

function Header() {

    const [isSticky, setIsSticky] = useState(false);
    const [navActive, setNavActive] = useState(false);

    // Get Menus Data Start
    const [mainMenu, setMainMenu] = useState<MenuData | null>(null);
    const [aboutMenu, setAboutMenu] = useState<MenuData | null>(null);
    const [contactUsMenu, setContactUsMenu] = useState<MenuData | null>(null);

    const MenuName = 'MENU_1';
    const aboutMenuName = 'MENU_2';
    const contactUsMenuName = 'MENU_3';
    useEffect(() => {
        const fetchAllMenus = async () => {
            const menuId = '28';
            try {
                const allMenus = await getAllMenus();
                const menuData = await getAllServiceMenu(menuId);
                // console.log("menuData",menuData)
                setMainMenu(getMenuByLocation(allMenus, MenuName));
                setAboutMenu(getMenuByLocation(allMenus, aboutMenuName));
                setContactUsMenu(getMenuByLocation(allMenus, contactUsMenuName));
            } catch (error) {
                console.error('Error fetching menus:', error);
            }
        };
        fetchAllMenus();
    }, [MenuName, aboutMenuName, contactUsMenuName]);
    // Get Menus Data Start


    // header toggle menu code start
        const toggleClass = (element: HTMLElement, stringClass: string) => {
            if (element.classList.contains(stringClass)) {
            element.classList.remove(stringClass);
            } else {
            element.classList.add(stringClass);
            }
        };
        const handleMenuClick = () => {
            const body = document.body;
            toggleClass(body, 'nav-active');
            setNavActive(!navActive);
        };
    // header toggle menu code end

    // Get Header Data
    const [headerData, setHeaderData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchHeaderData = async () => {
          try {
            const data = await getHeaderData();
            setHeaderData(data);
            setLoading(false);
          } catch (error) {
            console.error('Error: front header components fetching', error);
          }
        };
    
        fetchHeaderData();
      }, []);


    //   scroll to add sticky class add code
    useEffect(() => {
        
        const handleScroll = () => {
        if (window.scrollY > 1) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []); 
    //   scroll to add sticky class add code
    
    const handleNavLinkClick = () => {
        const body = document.body;
        if (body.classList.contains('nav-active')) {
          body.classList.remove('nav-active');
        }
      };
  return (
   <>
     {/* Header Start  */}
     <header className={`header ${isSticky ? 'sticky' : ''}`}>
            <div className="container-fluid" data-aos="fade" data-aos-delay="100">
                <div className="logo">
                    <Link onClick={handleNavLinkClick}  href="/" title="DevXcel Digital">
                    {
                        headerData?.header?.headerLogo ? (
                            <Image src={headerData?.header?.headerLogo?.sourceUrl} alt={headerData?.header?.headerLogo?.altText} width={70} height={70} loading ='eager'/>
                        ) : (
                            <Image src='/images/logo.svg' alt="logo" width={70} height={70}/>
                        )
                    }
                    </Link>
                </div>
                <div className="main_menu">
                    {
                    
                        !loading ? (
                            <>
                                {headerData?.header?.ctaButton && (
                                    <CommanBtn href={headerData?.header?.ctaButton.url} className='' target='_blank' text={headerData?.header?.ctaButton.title} />
                                )}
                            </>
                        ) : (
                            <>
                                <CommanBtn href="/" className='' text="book a discovery call" />
                            </>
                        )
                    
                    }
                   
                   
                    <div className="menu-icon" onClick={handleMenuClick}>
                        <span className="menu_icon__line"></span>
                        <span className="menu_icon__line"></span>
                        <span className="menu_icon__line"></span>
                    </div>
                </div>
            </div>
        </header>
    {/* Header End  */}
    <div className="clearfix"></div>
    {/* Menu */}
    <div className="navmenu">
        <div className="nav_content row">
            <div className="col left_menubar">
           
                {/* {mainMenu && (
                    <ul className='nav_list'>
                        {mainMenu.menuItems.nodes.map((menuItem) => (
                            <li key={menuItem.id} className="nav_item">
                                <Link onClick={handleNavLinkClick} href={menuItem?.uri}> {menuItem?.label} </Link>
                            </li>
                        ))}
                    </ul>
                )} */}

                {
                mainMenu ? (
                    <ul className='nav_list'>
                        {mainMenu.menuItems.nodes.map((menuItem) => (
                            <li key={menuItem.id} className="nav_item">
                                <Link onClick={handleNavLinkClick} href={menuItem?.uri}> {menuItem?.label} </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className='skeleton'>
                        {[...Array(6)].map((_, index) => (
                            <li key={index} className="skeleton_item">
                                <span>...</span>
                            </li>
                        ))}
                    </ul>
                )
                }
               
                <div className="social_media">
                    <SocialMedia/>
                </div>
            </div>
            <div className="col right_menubar">
                <div className="row">
                    <div className="col-xl-4 col-sm-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href="/services/web-development">Web Development</Link></h3>
                            <div className="row">
                                <div className="col">
                                    {/* <h4>Front End</h4> */}
                                    <ul>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development/wordpress">WordPress</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">Webflow</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">SquareSpace</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">Joomla</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">Drupal</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">PHP</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">CodeIgniter</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">Laravel</Link></li>
                                    </ul>
                                
                                </div>
                                <div className="col">
                                    {/* <h4>Back End</h4> */}
                                    <ul>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">NodeJS</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">React</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">Angular</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development">VueJS</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development/woocommerce">Woocommerce</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development/shopify">Shopify</Link></li>
                                        <li><Link onClick={handleNavLinkClick} href="/services/web-development/magento">Magento</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href="/services/mobile-development">Mobile Development</Link></h3>
                            <ul>
                                <li><Link onClick={handleNavLinkClick} href="/services/mobile-development">Flutter</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/mobile-development">Android</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/mobile-development">iOS</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/mobile-development">React Native</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href="/services/digital-marketing">Digital Marketing</Link></h3>
                            <ul>
                                <li><Link onClick={handleNavLinkClick} href="/services/digital-marketing">SEO</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/digital-marketing">SEM</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/digital-marketing">SMM</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/digital-marketing">PPC</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-6">
                        <div className="nav_col no_border">
                            <h3><Link onClick={handleNavLinkClick} href="/services/salesforce">Salesforce</Link></h3>
                            <ul>
                                <li><Link onClick={handleNavLinkClick} href="/services/salesforce">Salesforce Consulting</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/salesforce">Salesforce Customization</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/salesforce">Maintenance & Services</Link></li>
                                <li><Link onClick={handleNavLinkClick} href="/services/salesforce">Salesforce CPQ</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href={`/${aboutMenu?.slug}`}>{aboutMenu?.name}</Link></h3>
                            {aboutMenu && (
                                <ul>
                                    {aboutMenu.menuItems.nodes.map((menuItem) => (
                                        <li key={menuItem?.id}>
                                        <Link onClick={handleNavLinkClick} href={menuItem?.uri}> {menuItem?.label} </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="col-xl-4 col-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href={`/${contactUsMenu?.slug}`}>{contactUsMenu?.name}</Link></h3>
                            {contactUsMenu && (
                                <ul>
                                    {contactUsMenu.menuItems.nodes.map((menuItem) => (
                                        <li key={menuItem?.id}>
                                        <Link onClick={handleNavLinkClick} href={menuItem?.uri}> {menuItem?.label} </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div> */}
                    {/* banner start */}
                    <div className="col-xl-8">
                        {/* <div className="fit_img mega_banner_img">
                            <Image src="/images/megamenu-banner.png" width={1000} height={290} alt="banner" />
                        </div> */}
                        <div className="row tb_space">
                            <div className="col-md-4">
                                <div className="info_box white">
                                    <h3>Projects</h3>
                                    <span>1,375+</span>
                                    <p>Projects Delivered Successfully</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="info_box white">
                                    <h3>Happy Clients</h3>
                                    <span>758+</span>
                                    <p>Satisfied Clients</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="info_box white">
                                    <h3>Mugs of Coffee</h3>
                                    <span>1,000,000+</span>
                                    <p>Mugs of Coffee Brewed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* banner end */}
                    {/* <div className="col-xl-4 col-6">
                        <div className="nav_col">
                            <h3><Link onClick={handleNavLinkClick} href="/services/ai-automation">AI & Automation</Link></h3>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
   </>
  )
}

export default Header
